<template>
  <div class="expand">
    <el-tree :data="tree" :props="{ children: 'sections', label: 'name' }" @node-expand="handleExpand">
      <div slot-scope="{ node, data }" style="width: 100%;display: flex">
        <div
          style="flex: 1;color: #333333"
          :style="{
            'font-size': data.sections ? '14px' : '12px',
            'font-weight': data.sections ? 500 : 400
          }"
        >
          {{ node.label }}
        </div>
        <div style="color: #568FEB;flex: 1" :style="{ 'font-size': data.sections ? '14px' : '12px' }">参与用户&nbsp;{{ data.studyNum || 0 }}</div>
        <div style="width: 140px"></div>
      </div>
    </el-tree>
  </div>
</template>

<script>
import questionRepo from '../../common/old/questionOld'

export default {
  name: 'CourseExpand',
  props: {
    row: Object
  },
  data() {
    return {
      tree: []
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      questionRepo.getCourseADetail(this.row.courseId).then(res => {
        this.tree = res.res.map(item => {
          if (!item.sections) {
            item.sections = []
          }
          return item
        })
      })
    },
    handleExpand() {
      this.$emit('doLayout')
    }
  }
}
</script>

<style scoped lang="less">
.expand {
  background: rgba(247, 247, 247, 1);
  ::v-deep .el-tree {
    background: rgba(247, 247, 247, 1);
  }
}
</style>
